@import '@styles/sass/mixins/rem';

.customEditor {
  li {
    margin-left: rem(20);
  }
  a {
    text-decoration-line: underline;
    color: var(--buttonPrimaryBg);
    &:hover {
      color: var(--buttonPrimaryBgHover);
    }
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
}

@media only screen and (max-width: 767px) {
  .customEditor {
    table {
      max-width: 85vw;
      width: 85vw;
      font-size: 2vw;
    }
  }
}
